@media screen and (max-width: 600px) {
    div.home{
      grid-template-rows: 2rem auto auto 10rem;
      grid-template-columns: auto;
      grid-template-areas: "menu" "header" "projects" "footer";
    }
  
    div.home > div.header {
      margin-top: 2.25rem;
    }
  
    div.home > div.header, div.home > div.projects { 
      justify-self: center;
    }
  
    div.home > div.header > div.caption > p { 
      font-size: 18px !important;
    }
  
    div.home > div.header > div.caption > p.sd::after { 
      width: 12rem;
      height: 60px;
  
      background-color: white;
      transform: translate(-11rem, -1rem) rotate(-2deg);
      border-radius: 5px;
    }
  
    div.home > svg.circle {
      transform: translate(0, -18rem) !important;
    }
  
  }
  
  @media screen and (max-height: 680px) {
    div.home > svg.circle {
      transform: translate(0, -14rem) !important;
    }
  }
  
  @media screen and (max-height: 625px) {
    div.home > svg.circle {
      transform: translate(0, -12.5rem) !important;
    }
  }
  
  @media screen and (max-height: 570px) {
    div.home > svg.circle {
      transform: translate(0, -10.75rem) !important;
    }
  }