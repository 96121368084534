@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

:root {
  background-color: #212121;

  ::-moz-selection { /* Code for Firefox */
    background-color: #702963;
  }
  
  ::selection {
    background: #702963;
  }
}

* {
  margin: 0;
  font-family: "Space Mono", monospace !important;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.colorful {
  color: #702963 !important;
}

.colorful::-moz-selection { /* Code for Firefox */
  background-color: white !important;
}

.colorful::selection {
  background: white !important;
}