@media screen and (max-width: 900px) {
    div.blog-wrapper > div.previews {
        grid-gap: 2rem;
    }
}

@media screen and (max-width: 795px) {
    div.blog-wrapper > div.previews {
        grid-template-columns: auto !important;
        grid-template-rows: auto;

        justify-content: center;

        grid-gap: 5rem 0;
        padding: 2rem;
    }
}